.notification {
    .ant-notification-notice-icon {
        margin-left: 0;
    }
    
    .ant-notification-notice-message {
       margin-left: 12px;
       color: var(--Dark-navy, #1D1E2B);
       font-family: Montserrat;
       font-size: 16px;
       font-style: normal;
       font-weight: 600;
       line-height: 24px; /* 150% */
   }

   .ant-notification-notice-description {
       margin-left: 0;
       margin-top: 12px;
       color: var(--Dark-navy, #1D1E2B);
       font-family: Montserrat;
       font-size: 14px;
       font-style: normal;
       font-weight: 500;
       line-height: 20px; /* 142.857% */
   }
}